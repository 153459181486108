class AppState {
  static _storageAvailable(type) {
    var storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
  }

  static initUid() {
    if(AppState._storageAvailable('localStorage') && !localStorage.getItem(AppState.UID_KEY_NAME))
      localStorage.setItem(AppState.UID_KEY_NAME, uuidv4());
  }

  static getUid() {
    if(AppState._storageAvailable('localStorage'))
      return localStorage.getItem(AppState.UID_KEY_NAME);
  }
}

AppState.UID_KEY_NAME = 'neiuc_uid';
